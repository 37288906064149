<script setup lang="ts">
import { useUserStore } from 'core/stores/User'
import { useMobileCheck } from 'core/util'
import { ref } from 'vue'

const isMobile = useMobileCheck()

const userStore = useUserStore()

const display = ref(false)
const userInfoWrapper = ref()

async function logout() {
  await userStore.logout()
  window.location.reload()
}

function handleOutsideClick(e: Event) {
  e.stopPropagation()
  if (!userInfoWrapper.value.contains(e.target)) {
    display.value = false
    document.removeEventListener('click', handleOutsideClick)
    document.removeEventListener('touchstart', handleOutsideClick)
  }
}

function show() {
  if (display.value) {
    handleOutsideClick(new MouseEvent(''))
  } else {
    display.value = true
    document.addEventListener('click', handleOutsideClick)
    document.addEventListener('touchstart', handleOutsideClick)
  }
}

</script>
<template>
  <div v-if="userStore.loggedInUser" class="user-info" ref="userInfoWrapper">
    <img class="btn" :src="userStore.loggedInUser.profile_thumbnail" @click="show" alt="user profile thumbnail" />
    <div class="overlay" :class="{ 'open': display }" @click="display = false">
      <div class="block" style="display: flex;">
        <img :src="userStore.loggedInUser.profile_thumbnail" alt="user profile thumbnail" style="width: 65px; height: 64px; border-radius: 50%;" />
        <div>
          <p class="nickname">{{ userStore.loggedInUser.nickname }}</p>
          <p class="email">{{ userStore.loggedInUser.email }}</p>
        </div>
      </div>
      <nav class="block">
        <router-link class="btn" style="display: flex; align-items: center;" to="/user/dashboard">
          <img v-if="isMobile" src="https://cdn.etf-portfolio.com/image/icon-3d/folder.svg" alt="paper 3d icon" height="32" width="32" />
          <img v-else src="https://cdn.etf-portfolio.com/image/icon/dashboard.svg" alt="dashboard icon" class="grayscale" height="24" width="24" />{{ $t('user.user_info_01') }}
        </router-link>
        <router-link class="btn" style="display: flex; align-items: center;" to="/user/etf">
          <img v-if="isMobile" src="https://cdn.etf-portfolio.com/image/icon-3d/speaker.svg" alt="speaker icon" height="32" width="32" />
          <img v-else src="https://cdn.etf-portfolio.com/image/icon/bell.svg" alt="bell icon" class="grayscale" height="24" width="24" />{{ $t('user.user_info_02') }}
        </router-link>
        <!-- <router-link class="btn" style="display: flex; align-items: center;" to="/user/portfolio">
          <img src="https://cdn.etf-portfolio.com/image/icon/wallet.svg" class="grayscale" height="24" width="24" />ACCOUNT
        </router-link> -->
        <router-link class="btn" style="display: flex; align-items: center;" to="/user/profile">
          <img v-if="isMobile" src="https://cdn.etf-portfolio.com/image/icon-3d/user-icon.svg" alt="user icon" height="32" width="32" />
          <img v-else src="https://cdn.etf-portfolio.com/image/icon/user-settings.svg" alt="setting icon" class="grayscale" height="24" width="24" />{{ $t('user.user_info_03')
          }}
        </router-link>
      </nav>
      <div class="block info">
        <p v-if="!(userStore.user?.plan == 'FREE' && userStore.user?.haveBacktestPass)" style="position: relative;">
          <img v-if="isMobile" src="https://cdn.etf-portfolio.com/image/icon-3d/calendar.svg" alt="calendar icon" height="32" width="32" />
          <img v-else src="https://cdn.etf-portfolio.com/image/icon/membership.svg" alt="membership icon" />
          <label>{{ $t('user.user_plan') }} : </label><span style="color: #3952D7; font-weight: 700;"> {{ userStore.user?.plan
          }}</span>
          <router-link class="btn" to="/pricing" style="position: absolute; right: 0; padding: 0;">
            <button class="custom action-btn active">{{ $t('user.plan_upgrade') }}</button>
          </router-link>
        </p>
        <p v-if="userStore.user?.haveBacktestPass">
          <img v-if="isMobile" src="https://cdn.etf-portfolio.com/image/icon-3d/calendar.svg" alt="calendar icon" height="32" width="32" />
          <img v-else src="https://cdn.etf-portfolio.com/image/icon/membership.svg" alt="membership icon" /> {{ $t('user.term_pass') }} :
          <span>BACKTEST</span></p>
        <p>
          <img v-if="isMobile" src="https://cdn.etf-portfolio.com/image/icon-3d/monitor.svg" alt="cylinder icon" height="32" width="32" />
          <img v-else src="https://cdn.etf-portfolio.com/image/icon/briefcase.svg" alt="briefcase icon" />
          <label>{{ $t('user.user_info_04') }} : </label>
          <span>{{ userStore.loggedInUser.strategyCount }}/{{ userStore.strategyLimit }}</span>
        </p>
        <p>
          <img v-if="isMobile" src="https://cdn.etf-portfolio.com/image/icon-3d/setting.svg" alt="clock icon" height="32" width="32" />
          <img v-else src="https://cdn.etf-portfolio.com/image/icon/alarm.svg" alt="alarm icon" />
          <label>{{ $t('user.user_info_05') }} : </label>
          <span>{{ userStore.loggedInUser.etfAlarmCount }}/{{ userStore.etfAlarmLimit }}</span>
        </p>
        <p>
          <img v-if="isMobile" src="https://cdn.etf-portfolio.com/image/icon-3d/rocket.svg" alt="rocket icon" height="32" width="32" />
          <img v-else src="https://cdn.etf-portfolio.com/image/icon/counter.svg" alt="counter icon" />
          <label>{{ $t('user.user_info_06') }} : </label>
          <span>{{ userStore.loggedInUser.backtestCount }}/{{ userStore.backtestLimit }}</span>
        </p>
        <p v-if="userStore.user?.haveBacktestPass">
          <img v-if="isMobile" src="https://cdn.etf-portfolio.com/image/icon-3d/rocket.svg" alt="rocket icon" height="32" width="32" />
          <img v-else src="https://cdn.etf-portfolio.com/image/icon/counter.svg" alt="counter icon" />
          <label>{{ $t('user.user_info_07') }} : </label>
          <span>{{ userStore.user?.backtestPassCount }}</span>
        </p>
      </div>
      <div id="logout">
        <span class="btn" @click="logout"><img src="https://cdn.etf-portfolio.com/image/icon/logout.svg" alt="logout icon" width="24" height="24" />{{
          $t('user.user_info_08') }}</span>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "@/style/default.scss";

.user-info {
  position: relative;
  height: 32px;
  width: 32px;

  &>img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  .overlay {
    display: none;
    width: fit-content;
    position: absolute;
    top: 70px;
    right: 0;
    z-index: 10;
    background-color: #FAFBFF;
    padding: 16px;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.08));
    border-radius: 8px;
  }

  .overlay.open {
    display: block;
  }
}

@include mobile {
  .user-info {

    .overlay {
      position: fixed;
      display: block;
      z-index: 223;
      transition: top 0.4s ease;
      right: 16px;

      &:not(.open) {
        top: -110vh;
      }
    }
  }
}


.nickname {
  margin-left: 16px;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  color: $black9;
}

.email {
  margin-left: 16px;
  font-size: 14px;
  line-height: 32px;
  letter-spacing: 0.25px;
  color: $black7;
}

nav {
  width: 344px;
  color: $black8;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include mobile {
    width: calc(100vw - 64px);
  }

  font-size: 16px;

  img {
    margin-right: 8px;
  }

  a {
    font-size: 14px;
    white-space: pre;
    width: 100%;
  }
}

.block {
  background: #FFFFFF;
  border-radius: 8px;
  margin-bottom: 8px;
  padding: 16px;
  box-shadow: 0 0 2px rgba(145, 158, 171, 0.2), 0 12px 24px rgba(145, 158, 171, 0.12);
}

.info {
  color: $black8;
  font-size: 16px;

  p {
    img {
      margin-right: 8px;
    }

    display: flex;
    align-items: center;
    width: 100%;
    height: 32px;
    font-size: 14px;

    label {
      margin-right: 8px;
    }

    button {
      padding: 10px;
      font-weight: 600;
      height: 32px;
      font-size: 14px;
    }
  }

  p:not(:last-child) {
    margin-bottom: 16px;
  }
}

#logout {
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    margin-right: 8px;
  }

  span {
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }

}</style>